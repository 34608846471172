<template>
  <calendesk-tabs v-show="getStatsLoaded" :items="getItems" :route="route" />
</template>

<script>
import CalendeskTabs from "@/components/CTabs/CalendeskTabs.vue";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";
import path from "@/router/path";

export default {
  name: "SettingsWrapper",
  components: { CalendeskTabs },
  mixins: [sharedActions, roleActions, sharedTabsActions],
  data() {
    return {
      route: path.settings.main,
    };
  },
  computed: {
    getItems() {
      const items = [];

      if (
        this.loggedUser &&
        (!this.loggedUser.employee.id || !this.loggedUserCanEditEmployees)
      )
        items.push({
          id: this.availableTabs.settings.profile,
          title: this.$trans("profile_account"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.settings.profile },
          },
          content: () =>
            import("@/views/dashboard/pages/Settings/YourProfileForm"),
        });

      if (this.loggedUserCanSeePayments && this.loggedUserCanEditSettings) {
        items.push({
          id: this.availableTabs.settings.company,
          title: this.$trans("profile_company"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.settings.company },
          },
          content: () =>
            import("@/views/dashboard/pages/Settings/ContactDataForm"),
        });
      }

      if (this.loggedUserCanEditSettings) {
        items.push({
          id: this.availableTabs.settings.platform,
          title: this.$trans("settings_platform"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.settings.platform },
          },
          content: () =>
            import("@/views/dashboard/pages/Settings/PlatformFullForm"),
        });

        if (this.loggedUserCanEditServices) {
          items.push({
            id: this.availableTabs.settings.locations,
            title: this.$trans("service_locations"),
            props: {},
            route: {
              name: this.route,
              params: { tab: this.availableTabs.settings.locations },
            },
            content: () =>
              import("@/views/dashboard/pages/Settings/LocationList"),
          });
        }

        if (this.loggedUserCanEditTags) {
          items.push({
            id: this.availableTabs.settings.tags,
            title: this.$trans("tags"),
            props: {},
            route: {
              name: this.route,
              params: { tab: this.availableTabs.settings.tags },
            },
            content: () => import("@/views/dashboard/pages/Settings/TagList"),
          });
        }

        if (this.loggedUserCanEditGroups) {
          items.push({
            id: this.availableTabs.settings.groups,
            title: this.$trans("groups"),
            props: {},
            route: {
              name: this.route,
              params: { tab: this.availableTabs.settings.groups },
            },
            content: () => import("@/views/dashboard/pages/Settings/GroupList"),
          });
        }

        if (this.loggedUserCanEditResources) {
          items.push({
            id: this.availableTabs.settings.resources,
            title: this.$trans("resources"),
            props: {},
            route: {
              name: this.route,
              params: { tab: this.availableTabs.settings.resources },
            },
            content: () =>
              import("@/views/dashboard/pages/Settings/ResourceList"),
          });
        }
      }

      return items;
    },
  },
};
</script>
